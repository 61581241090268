:root {
  --black10: #00000010;
  --black30: #00000030;
  --black50: #00000050;
  --black70: #00000070;
  --yellow: #f9ed69;
  --orange: #f08a5d;
  --red: #b83b5e;
  --purple: #6a2c70;
  --blue: #2884cf;
  --green: #39a03e;

  --border: 1px solid;
  --shadow: 0px 5px 10px -1px;
  --border-radius: 15px;
  --padding: 1rem;
  --bodyPadding: 1rem
}

@media (min-width: 500px) {
  :root {
    --bodyPadding: 2rem 2rem;
  }
}

@media (min-width: 630px) {
  :root {
    --bodyPadding: 2rem 3rem;
  }
}

@media (min-width: 1000px) {
  :root {
    --bodyPadding: 2rem 3rem;
  }
}

@media (min-width: 1300px) {
  :root {
    --bodyPadding: 2rem 10rem;
  }
}